import React from "react"
import { Link } from "gatsby"

const BlogPageDetails = props => {
  if (props.blogPageType === "blogAuthor") {
    return (
      <div className="section-blog-details">
        <div className="container">
          <div className="img-holder">
            <img src={props.authorImage} alt="" />
          </div>
          <p className="subheading-1">{props.authorName}</p>
        </div>
      </div>
    )
  } else if (props.blogPageType === "blogCategory") {
    return (
      <div className="section-blog-details">
        <div className="container">
          <p className="subheading-1">{props.category}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="section-blog-details">
      <div className="container container-sm">
        <div className="img-holder">
          <img src={props.authorImage} alt="" />
        </div>
        <p className="subheading-1">
          <Link to={`/author/${props.authorSlug}`} className="text-underline">
            {props.authorName}
          </Link>{" "}
          in{" "}
          <Link
            to={`/category/${props.categorySlug}`}
            className="text-underline"
          >
            {props.category}
          </Link>
        </p>
      </div>
    </div>
  )
}

export default BlogPageDetails
