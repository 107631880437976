import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogForm from "../components/blog-form"
import BlogPageDetails from "../components/blog-page-details"
// import { Helmet } from 'react-helmet'
import Moment from "react-moment"
import { Remarkable } from "remarkable"
import RemarkableReactRender from "remarkable-react"
import GraphImg from "graphcms-image"

const md = new Remarkable()
md.renderer = new RemarkableReactRender()

const BlogPage = ({ data }) => {
  const blogDetails = {
    title: data.gcms.blogPost.title,
    publicationDate: data.gcms.blogPost.publicationDate,
    featuredImage: data.gcms.blogPost.image,
    postSlug: data.gcms.blogPost.slug,
    authorImage: "",
    authorName: "",
    category: "",
    content: data.gcms.blogPost.content,
  }

  if (data.gcms.blogPost.blogAuthor !== null) {
    blogDetails.authorImage = data.gcms.blogPost.blogAuthor.profilePicture.url
  }

  if (data.gcms.blogPost.blogAuthor !== null) {
    blogDetails.authorName = data.gcms.blogPost.blogAuthor.name
  }

  if (data.gcms.blogPost.blogCategory != null) {
    blogDetails.category = data.gcms.blogPost.blogCategory.categoryName
  }

  return (
    <Layout isHeaderTransparent={true}>
      <SEO
        title={blogDetails.title + " | The Billsby Blog"}
        description={
          "Learn about" +
          blogDetails.title +
          "and other updates on subscription billing and recurring payments."
        }
        url={
          "https://www.billsby.com/" +
          blogDetails.publicationDate +
          "/" +
          blogDetails.postSlug
        }
      />

      <div
        className="section-blog-banner"
        // style={{backgroundImage: `url(${blogDetails.featuredImage})` }}
      >
        <GraphImg image={blogDetails.featuredImage} maxWidth={1920} />

        <div className="container">
          <h1 className="heading-1">{blogDetails.title}</h1>
          <p className="subheading-1">
            <Moment format="MMMM DD YYYY">{blogDetails.publicationDate}</Moment>
          </p>
          <BlogForm buttonLabel="Get more posts like this one" />
        </div>
      </div>

      {data.gcms.blogPost.blogAuthor !== null &&
        data.gcms.blogPost.blogCategory != null && (
          <BlogPageDetails
            authorSlug={data.gcms.blogPost.blogAuthor.slug}
            authorImage={data.gcms.blogPost.blogAuthor.profilePicture.url}
            authorName={data.gcms.blogPost.blogAuthor.name}
            categorySlug={data.gcms.blogPost.blogCategory.slug}
            category={data.gcms.blogPost.blogCategory.categoryName}
          />
        )}

      <div className="section-main-content">
        <div className="container container-sm">
          <div className="content">{md.render(blogDetails.content)}</div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query BlogTemplate($postId: ID!) {
    gcms {
      blogPost(where: { id: $postId }, stage: PUBLISHED) {
        title
        slug
        publicationDate
        blogAuthor {
          ... on GraphCMS_Author {
            profilePicture {
              url
            }
            name
            slug
          }
        }
        image: featuredImage {
          handle
          width
          height
        }
        content
        blogCategory {
          categoryName
          slug
        }
      }
    }
  }
`
